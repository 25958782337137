import React, { Fragment } from "react"
import { ldsRingClass, centerClass } from "./Loader.module.scss"

export const Loader = ({ center }) => (
  <div className={`${ldsRingClass} ${center ? centerClass : ""}`}>
    <div />
    <div />
    <div />
    <div />
  </div>
)

export const BtnLoader = () => (
  <Fragment>
    &nbsp;
    <span
      className="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"></span>
    <span className="visually-hidden">Loading...</span>
  </Fragment>
)
