import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import loadable from "@loadable/component"

import Footer from "../components/Footer"
import SearchFeature from "../components/AcfPageBlocks/SearchFeature";

const BannerImage = loadable(() => import('../components/AcfPageBlocks/BannerImage'))
const BannerWithContent = loadable(() => import('../components/AcfPageBlocks/BannerWithContent'))
const ContactForm = loadable(() => import('../components/AcfPageBlocks/ContactForm'))
const DetailSummaryItems = loadable(() => import('../components/AcfPageBlocks/DetailSummaryItems'))
const Feedback = loadable(() => import('../components/AcfPageBlocks/Feedback'))
const ImageAndTextPanel = loadable(() => import('../components/AcfPageBlocks/ImageAndTextPanel'))
const InfoWithLeftBkgdImg = loadable(() => import('../components/AcfPageBlocks/InfoWithLeftBkgdImg'))
const NewsletterSubscription = loadable(() => import('../components/AcfPageBlocks/NewsletterSubscription'))
const PlainText = loadable(() => import('../components/AcfPageBlocks/PlainText'))
const TextBoxAndVidOrImg = loadable(() => import('../components/AcfPageBlocks/TextBoxAndVidOrImg'))
const TwoTextBoxesWithFill = loadable(() => import('../components/AcfPageBlocks/TwoTextBoxesWithFill'))
const Video = loadable(() => import('../components/AcfPageBlocks/Video'))
const LeftTextWithRightBox = loadable(() => import('../components/AcfPageBlocks/LeftTextWithRightBox'))
const NavWithHeaderAndText = loadable(() => import('../components/AcfPageBlocks/NavWithHeaderAndText'))
const LeftImageWithTextAndBtn = loadable(() => import('../components/AcfPageBlocks/LeftImageWithTextAndBtn'))
const TabDetail = loadable(() => import('../components/AcfPageBlocks/TabDetail'))
const LeftImageWithText = loadable(() => import('../components/AcfPageBlocks/LeftImageWithText'))
const FrequentlyAskedQuestions = loadable(() => import('../components/AcfPageBlocks/FrequentlyAskedQuestions'))
const ImgAndLinkList = loadable(() => import('../components/AcfPageBlocks/ImgAndLinkList'))
const PagePullQuote = loadable(() => import('../components/AcfPageBlocks/PagePullQuote'))
const FundraisingInterestForm = loadable(() => import('../components/AcfPageBlocks/FundraisingInterestForm'))
const LeafletRequestForm = loadable(() => import('../components/AcfPageBlocks/LeafletRequestForm'))
const CallbackRequestForm = loadable(() => import('../components/AcfPageBlocks/CallbackRequestForm'))
const TulipsTributesContactForm = loadable(() => import('../components/AcfPageBlocks/TulipsTributesContactForm'))
const VolunteersForm = loadable(() => import('../components/AcfPageBlocks/VolunteersForm'))

const Page = ({ location, data, crumbLabel, history, pageContext }) => {
  const {
    title,
    content,
    flexLayouts: { flexibleLayouts },
    jsonLDSchema,
    seo,
    date
  } = data.wpPage

  return (
    <>
      <Layout seo={seo} location={location}>
        {/*<Breadcrumb data={breadcrumbData}/>*/}
        <main>
          {content && (
            <div
              className={title}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
          {flexibleLayouts?.length &&
            flexibleLayouts.map((block, i) => {
              switch (block.__typename) {
                case "WpPage_Flexlayouts_FlexibleLayouts_BannerImage":
                  return <BannerImage index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_BannerWithContent":
                  return <BannerWithContent index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_ContactFormText":
                  return <ContactForm index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_TulipsTributesContactForm":
                  return <TulipsTributesContactForm index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_DetailSummaryItems":
                  return <DetailSummaryItems index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_Feedback":
                  return <Feedback index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_Footer":
                  return <Footer index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_ImageAndTextPanel":
                  return <ImageAndTextPanel index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_InfoWithLeftBkgdImg":
                  return <InfoWithLeftBkgdImg index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_NewsletterSubscription":
                  return <NewsletterSubscription index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_PlainText":
                  return <PlainText index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_TextBoxAndVidOrImg":
                  return <TextBoxAndVidOrImg index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_TwoTextBoxesWithFill":
                  return <TwoTextBoxesWithFill index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_Video":
                  return <Video index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_LeftTextWithRightBox":
                  return <LeftTextWithRightBox index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_NavWithHeaderAndText":
                  return (
                    <NavWithHeaderAndText
                      jsonLDSchema={jsonLDSchema}
                      publishedDate={date}
                      index={i}
                      key={i}
                      {...block}
                      location={location}
                      crumbLabel={title}
                      history={history}
                      pageContext={pageContext}
                    />
                  )
                case "WpPage_Flexlayouts_FlexibleLayouts_SearchFeature":
                  return (
                      <SearchFeature
                          index={i}
                          key={i}
                          {...block}
                          location={location}
                          crumbLabel={title}
                          pageContext={pageContext}
                      />
                  )
                case "WpPage_Flexlayouts_FlexibleLayouts_LeftImageWithTextAndBtn":
                  return <LeftImageWithTextAndBtn index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_TabDetail":
                  return <TabDetail index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_LeftImageWithText":
                  return <LeftImageWithText index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_FrequentlyAskedQuestions":
                  return <FrequentlyAskedQuestions index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_ImgAndLinkList":
                  return <ImgAndLinkList index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_PagePullQuote":
                  return <PagePullQuote index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_FundraisingInterestForm":
                  return <FundraisingInterestForm index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_LeafletRequestForm":
                  return <LeafletRequestForm index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_CallbackRequestForm":
                  return <CallbackRequestForm index={i} key={i} {...block} />
                case "WpPage_Flexlayouts_FlexibleLayouts_VolunteersForm":
                  return <VolunteersForm index={i} key={i} {...block} />
                default:
                  console.log("This is the End!")
                  return "" //FIXME this should not happen
              }
            })}
        </main>
      </Layout>
    </>
  )
}

export default Page

export const pageQuery = graphql`
  query GET_PAGE($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      uri
      date
      flexLayouts {
        flexibleLayouts {
          __typename
          ...bannerImageFragment
          ...bannerWithContentFragment
          ...contactFormFragment
          ...detailSummaryItemsFragment
          ...feedbackFragment
          ...imageAndTextPanelFragment
          ...infoWithLeftBkgdImgFragment
          ...newsletterSubscriptionFragment
          ...plainTextFragment
          ...textBoxAndVidOrImgFragment
          ...twoTextBoxesWithFillFragment
          ...videoFragment
          ...leftTextWithRightBoxFragment
          ...navWithHeaderAndTextFragment
          ...searchFeatureFragment
          ...leftImageWithTextAndBtnFragment
          ...tabDetailFragment
          ...leftImageWithTextFragment
          ...frequentlyAskedQuestions
          ...imgAndLinkList
          ...pagePullQuoteFragment
          ...fundraisingInterestFormFragment
          ...leafletRequestFormFragment
          ...callbackRequestFormFragment
          ...tulipsTributesContactFormFragment
          ...volunteersFormFragment
        }
      }
      jsonLDSchema {
        medicalSchema {
          mainContentOfPage
          enabled
          authors {
            biographyLink
            fieldGroupName
            name
          }
          lastReview
          nextReview
          reviewers {
            biographyLink
            fieldGroupName
            name
          }
        }
      }
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
      }
    }
  }
`
