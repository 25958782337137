import React, { memo, useEffect, useRef, useState } from "react"
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup
} from "react-bootstrap"

import {
  sectionClass,
  formControlClass,
  clearBtn,
  resultSlug,
  resultCategory
} from "./SearchForm.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { search } from "../../api/search"
import Link from "../UI/Link"
import { BtnLoader } from "../UI/Loader"

const SearchForm = ({ location }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  //const [status, setStatus] = useState({ initial: true })
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [isSearchComplete, setIsSearchComplete] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")
  const [results, setResults] = useState([])
  const [resultsPagination, setResultsPagination] = useState({})

  const inputRef = useRef(null)
  const [value, setValue] = useState("")

  const validate = e => {
    const form = e.currentTarget
    const query = form.elements.search.value.trim()

    if (form.checkValidity() === true && query.length > 2) {
      setSubmitDisabled(false)
    } else {
      e.stopPropagation()
      setSubmitDisabled(true)
    }
  }

  const onInput = e => setValue(e.target.value)

  const clearSearch = e => {
    e.preventDefault()
    setValue("")
  }

  // hide keyboard on mobile devices when user hits the 'enter' button
  const onKeyUp = e => {
    if (e.key === "Enter") {
      e.target.blur()
    }
  }

  const handleSearch = e => {
    e.preventDefault()
    performSearch({ searchText: value })
  }

  const loadMoreResults = () => {
    const currentPage = resultsPagination.currentPage
    const newCurrentPage = currentPage + 1
    performSearch({ searchText: value, page: newCurrentPage }, false)
  }

  const performSearch = async (query, isFirstResult = true) => {
    try {

      setIsSubmitting(true)
      const { searchText, page } = query

      updateUrl(searchText)

      const response = isFirstResult
      ? await search(searchText)
      : await search(searchText, page)

      setIsSearchComplete(true)
      setIsSubmitting(false)
      setSearchQuery(searchText)

      const pagination = {...response.data}
      delete pagination.data

      isFirstResult
        ? setResults(response.data.data)
        : setResults([...results, ...response.data.data])

      setResultsPagination(pagination)


    } catch (exception) {
      setIsSearchComplete(false)
      setIsSubmitting(false)
      throw new Error(exception)
    }
  }

  const updateUrl = (searchText) => {
    if (typeof window !== "undefined") {
      const url = new URL(window.location.href);
      const searchTextInUrl = url.searchParams.get("query");

      if(searchText !== searchTextInUrl) {
        const updatedUrl = url.origin + url.pathname + '?query=' + searchText
        window.history.pushState({}, '', updatedUrl);
      }
    }
  }

  useEffect(() => {
    let urlParams = new URLSearchParams(location.search.substring(1))
    const urlQuery = urlParams.get("query")
    if (urlQuery !== "" && urlQuery !== null) {
      setValue(urlQuery)
      performSearch({ searchText: urlQuery })
    }
  }, [location])




  return (
    <section className={sectionClass}>
      <Container className="px-0">
        <Row>
          <Col md={10} className="mx-auto">
            <Form
              role="search"
              noValidate
              onChange={validate}
              onSubmit={handleSearch}
              method="post">
              <>
                <Row>
                  <Col md={12}>
                    <InputGroup className="mb-3" size="lg">
                      <Form.Control
                        className={formControlClass}
                        name="search"
                        placeholder="Search our website"
                        aria-label="Search our website"
                        autocomplete="off"
                        autocorrect="off"
                        spellcheck="off"
                        aria-describedby="submitBtn"
                        enterkeyhint="search"
                        type="search"
                        ref={inputRef}
                        onChange={onInput}
                        onKeyUp={onKeyUp}
                        value={value}
                        required
                      />
                      <Button
                        variant="secondary"
                        type="submit"
                        disabled={submitDisabled || isSubmitting}
                        id="submitBtn">
                        {!isSubmitting && (
                          <FontAwesomeIcon
                            color="white"
                            icon={faSearch}
                            size="lg"
                          />
                        )}

                        {isSubmitting && <BtnLoader />}
                      </Button>
                      {value?.length > 0 && (
                        <a href="" onClick={clearSearch} className={clearBtn}>
                          <FontAwesomeIcon icon={faTimesCircle} size="lg" />
                        </a>
                      )}
                    </InputGroup>
                  </Col>
                </Row>
              </>
            </Form>
          </Col>
          {isSearchComplete && (
            <Col md={10} className="mx-auto">
              <h5 className="my-3">
                {resultsPagination.totalElements} results found for '{searchQuery}'
              </h5>
              {results.length > 0 && (
                <>
                  <ul className="list-unstyled">
                    {results.map((item, i) => (
                      <li className="mb-5" key={`Item_${i}`}>
                        <Link
                          key={`link_${item.id}`}
                          classes={resultSlug}
                          link={`${item.uri}`}
                          title={item.title}
                        />
                        <p className="mt-2">{item.additionalText}</p>
                        {
                          item.categories.length > 0 && (
                            <div className={resultCategory}>
                              <p className="mb-0 d-inline-block">
                                {item.categories.map((category, i) =>
                                  <span key={`category_${i}`}>
                                    {category}
                                    {item.categories.length > i+1 && (', ')}
                                  </span>
                                )}
                              </p>
                            </div>
                          )
                        }
                      </li>
                    ))}
                  </ul>
                  {
                    Object.keys(resultsPagination).length > 0 && resultsPagination.currentPage !== resultsPagination.totalPages && (
                      <div className="text-center mb-5">
                        <Button
                          variant="outline-secondary"
                          disabled={isSubmitting}
                          onClick={loadMoreResults}
                          type="button"
                          id="loadMore">
                          {!isSubmitting && <span>Load More</span>}
                          {isSubmitting && <BtnLoader />}
                        </Button>
                      </div>
                    )
                  }
                </>
              )}
              {results.length === 0 && (
                <>
                  <p>Sorry, but we couldn't find what you were looking for.</p>
                  <p>Please:</p>
                  <ul>
                    <li>Make sure all words are spelt correctly.</li>
                    <li>Try different keywords</li>
                    <li>Try fewer words</li>
                  </ul>
                </>
              )}
            </Col>
          )}
        </Row>
      </Container>
      {/* {status && status.success && (
        <Container className={successContainerClass}>
          <div className={successMsgClass}>{status.success}</div>
        </Container>
      )}
      {status && status.exception && (
        <Alert variant="danger" className="my-5">
          <Alert.Heading>{status.exception}</Alert.Heading>
        </Alert>
      )} */}
    </section>
  )
}

export default memo(SearchForm)
