import axios from "axios"

export const search = async (searchText, page = 0, maxResults = 10) => {
  const params = {
    searchText,
    maxResults,
    page
  }

  // todo: change to ectopic api endpoint
  const config = {
    baseURL: `${process.env.SEARCH_API_URL}`,
    params
  }

  const response = await axios.get(`/search`, config)

  return response
}
