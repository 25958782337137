import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { titleCase, trailingPath } from "../../utils"
import SectionHeading from "../UI/SectionHeading"
import SearchForm from "./SearchForm";

export const fragment = graphql`
  fragment searchFeatureFragment on WpPage_Flexlayouts_FlexibleLayouts_SearchFeature {
    sectionHeader
    headingType
    paragraph
  }
`
const SearchFeature = ({
                                  sectionHeader,
                                  paragraph,
                                  location,
                                  pageContext,
                                  crumbLabel,
                                  headingType,
                              }) => {
    const [hasBanner, setHasBanner] = useState(false)

    useEffect(() => {
        const banner = document.getElementById("banner")
        banner && setHasBanner(true)
    }, [])

    const topSpacing = hasBanner
        ? { marginTop: "-6px" }
        : { marginTop: "inherit" }

    const {
        breadcrumb: { crumbs }
    } = pageContext

    const cleanedCrumbs = crumbs.map(item => {
        const newLable = item.crumbLabel.replace("-", " ")
        return {
            pathname: trailingPath(item.pathname),
            crumbLabel: titleCase(newLable)
        }
    })

    const cleanedLabel = titleCase(crumbLabel.replace("-", " "))

    return (
        <div
            className={hasBanner ? "pt-5 bg-pale-grey" : "pt-5 pt-lg-11 bg-pale-grey"}
            style={topSpacing}>
            <Container className="px-3 px-md-0">
                <Row className="d-flex justify-content-center">
                    <Col className="d-flex flex-column">
                        <Breadcrumb
                            crumbs={cleanedCrumbs}
                            crumbSeparator=" / "
                            crumbLabel={cleanedLabel}
                        />
                        <Row>
                            <SectionHeading className="pt-5" text={sectionHeader} type={headingType} />
                        </Row>
                        {paragraph && (
                            <div
                                className={`navText pb-4 pb-lg-5 pt-3 pt-lg-4`}
                                dangerouslySetInnerHTML={{ __html: paragraph }}
                            />
                        )}
                        <SearchForm location={location}></SearchForm>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SearchFeature
